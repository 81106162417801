<template>
    <div class="container mt-4 mb-5" style="text-align: left">
        <h1>TESTE DE FORMATAÇÃO</h1>
        <div class="row mb-5">
            <div class="col-9">
                <h3>{{nome_curso}} </h3>
            </div>
            <div class="col" style="text-align: right">
                <b-button variant="outline-primary" style="margin-left: 10px;" to="/">Voltar</b-button>
            </div>
        </div>
        
        <!-- 1. Apresentação e justificativa -->
        <div class="row mb-2 mt-4">
            <h2>1. Apresentação e Justificativa</h2>
            <hr>
        </div>

        <!-- 1.3 Justificativa para oferta do curso" -->
        {{formatarString(formulario_especifico.item_1_3)}}
        <hr>
        {{formulario_especifico.item_1_3}}


        <!-- 1.4. Relevância da oferta (evidenciar a necessidade de profissionais com a formação na localidade). -->
        {{formatarString(formulario_especifico.item_1_4)}}
        <hr>
        {{formulario_especifico.item_1_4}}

        
        <!-- 1.5. Objetivos do Curso: -->
        <div class="row mb-2">
            <h4>1.5. Objetivos do Curso:</h4>
        </div>

        <!-- 1.5.1. Objetivo Geral.  -->
        {{formatarString(formulario_especifico.item_1_5_1)}}
        <hr>
        {{formulario_especifico.item_1_5_1}}
  
        <!-- 1.5.2. Objetivos Específicos.  -->
        {{formatarString(formulario_especifico.item_1_5_2)}}
        <hr>
        {{formulario_especifico.item_1_5_2}}

        
        <!-- 3. Perfil profissional de conclusão.  -->
        <h2>3. Perfil profissional de conclusão</h2>
        {{formatarString(formulario_especifico.item_3)}}
        <hr>
        {{formulario_especifico.item_3}}
           
        
        <!-- 3.1 Habilidades e competências desenvolvidas na formação.  -->
        {{formatarString(formulario_especifico.item_3_1)}}
        <hr>
        {{formulario_especifico.item_3_1}}

        <!-- 4. Organização Curricular.  -->
        <div class="row mb-2 mt-4">
            <h2>4. Organização Curricular</h2>
            <hr>
        </div>
        
        <!-- 4.1. Representação gráfica da organização curricular e do itinerário formativo.  -->
        <div class="row mb-4">
            <h6><strong>4.1. Representação gráfica da organização curricular e do itinerário formativo</strong></h6>
            <uploadImgInput :curso_id="$route.params.id_firebase" item="item_4_1"></uploadImgInput>
        </div>
        
        <!-- 4.2. Apresentação das Unidades Curriculares.  -->
        <div class="row mb-2">
            <h4>4.2. Apresentação das Unidades Curriculares</h4>
        </div>
        
        
        <!-- 4.3. Plano de realização do Estágio Curricular (somente Enf E radio) -->
        {{formatarString(formulario_especifico.item_4_3)}}
        <hr>
        {{formulario_especifico.item_4_3}}

        <!-- 4.10 Atividades práticas previstas -->
        {{formatarString(formulario_especifico.item_4_10)}}
        <hr>
        {{formulario_especifico.item_4_10}}


         
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';
import uploadImgInput from '@/views/teste_nadir/components/upload_img.vue'
// import itemInput from '@/views/teste_nadir/components/itemInput.vue'

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    components: { 
        uploadImgInput,
        // itemInput,
    },
    data() {
        return {
            teste_real_time: [],
            responsavel: '',
            nome_curso: '',
            formulario_especifico: [],     
            user: null,
            id_curso_atual: this.$route.params.id_firebase
        }
    },
    computed: {
        soma_carga_unidades_curriculares: function(){
            let soma = 0
            if(this.formulario_especifico.item_4_2_1){
                this.formulario_especifico.item_4_2_1.forEach( x => soma = soma + parseInt(x.carga_horaria))
            }     
            return soma
        },

        num_unidades_curriculares: function(){
            let num = 0
            num = this.formulario_especifico.item_4_2_1.length
            return num
        }
    },
    methods: {
        formatarString(stringElm){
            // if(stringElm){
            //     let htmlObject = document.createElement('div');
            //     htmlObject.innerHTML = stringElm;

            //     let array_elements = Array.from(htmlObject.children)
            //     let array_names = []
            //     try{
            //         array_names = array_elements.map(x => x.tagName)
            //     }catch(e){console.log(e)}
                
                

            //     console.log(htmlObject)
            //     return array_names
            // }
            

            



            if(stringElm){
                const doc = new DOMParser().parseFromString(stringElm, 'text/html');
                const arr = [...doc.body.childNodes]
                .map(child => child.outerHTML || child.textContent);
                console.log('Array gerado:', arr, doc);

                let array = arr.map(x => {
                    // console.log(this.detectTagname(x))

                    try{
                        if(this.detectTagname(x) == 'UL'){
                            return this.converterTextToListLatex(x)
                        } else if(this.detectTagname(x) == 'P'){
                            // console.log(x)
                            return x.match(/<\s*p[^>]*>([^<]*)<\s*\/\s*p\s*>/)[1]
                        } else {
                            return x
                        }
                    }catch(e){
                        console.log(e)
                    }
                })
                return array
            }

            return stringElm
        },

        converterTextToListLatex(list_itens){
            let htmlObject = document.createElement('div');
            htmlObject.innerHTML = list_itens;
            let elem_list_itens = htmlObject.childNodes[0].childNodes
            let latext_list = ' \\begin{itemize}'
            for (let i=0 ; i<elem_list_itens.length ; i++){
                console.log(elem_list_itens[i].textContent)
                let item = ' \\item ' + elem_list_itens[i].textContent// eslint-disable-line
                latext_list += item
            }
            latext_list += " \\end{itemize}"// eslint-disable-line
            console.log(latext_list)
            this.text_latex = latext_list
            return latext_list
        },

        log(value){
            console.log('Item atualizado', value)
            // Após o elemento concluir a inserção de dados no firebase, atualiza os dados
            this.atualizarDados()
        },

        atualizarDados(){
            db.collection("/nadir").doc(this.$route.params.id_firebase)
            .get()
            .then((doc) => {
                this.formulario_especifico = doc.data().formulario_especifico
                this.nome_curso = doc.data().nome_curso
                this.responsavel = doc.data().responsavel
                console.log(this.formulario_especifico)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        },

        cadastrar(){
            db.collection("/nadir").doc(this.$route.params.id_firebase)
            .update({formulario_especifico: this.formulario_especifico})
            .then(() => {
                alert('Alterações salvas com sucesso')
                console.log("Document successfully updated!");
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
            
        },

        addItem_4_2_1(){
            this.formulario_especifico.item_4_2_1.push(
                {
                    nome_unidade: '',
                    carga_horaria: '',
                    competencias: '',
                    habilidades: '',
                    atitudes: '',
                    praticas: '',
                    avaliacao: '',
                    bibliografia_basica: [],
                    bibliografia_complementar: []
                }
            )
        },

    },
    mounted() {
        console.log(this.formulario_especifico.item_4_1_1)
        if(this.$route.params.id_firebase){
            this.atualizarDados()
        } else {
            alert('Nenhum curso encontrado para o ID especificado')
        }
    }
}
</script>

<style scoped>
p{
    text-indent: 50px;
}
</style>